import React from 'react'
import ReactMarkdown from 'react-markdown'

import Layout from '../layout'
import SEO from '../utils/seo'

import { GalleryCatalog } from '../components/individualCatalog'
import {
    IntroProduct,
    ResumeProduct,
    GalleryProduct,
    ContentProduct,
} from '../components/Modules/Solutions/styles'
import Divider from '../components/Divider'

const SolutionPage = () => {
    let title = 'IMPRESSORA DIGITAL DE RÓTULOS E ETIQUETAS - N730I'
    const transformTitleInArray = title.split(' ')
    const lastWordInTitle = transformTitleInArray.pop()
    title = transformTitleInArray.join(' ')

    return (
        <Layout>
            <SEO title="Setor" />

            <IntroProduct>
                <div className="content-product-wrapper">
                    <h1 className="title-product">
                        {title} <b>{lastWordInTitle}</b>
                    </h1>

                    <Divider size={4} orientation="horizontal" />

                    <div className="indicated-sectors">
                        <div className="text-indicated-sectors">
                            <p>Indicado para:</p>
                        </div>

                        <div className="icons-indicated-sectors">
                            <img
                                src="https://www.sunnyvale.com.br/wp-content/uploads/2020/10/003-cosmetics-copy.svg"
                                alt="Tabaco"
                                className="icons-img"
                            />
                        </div>
                    </div>
                </div>

                <div className="image-product-wrapper">
                    <img
                        src={
                            'https://www.sunnyvale.com.br/wp-content/uploads/2020/10/D-Series_Laser_D620i_IP65_controlador_TouchPanel2-removebg-preview-aspect-ratio-200-200.png'
                        }
                        alt="Nome do produto"
                        className="image-product"
                    />
                </div>
            </IntroProduct>

            <Divider size={1} orientation="horizontal" />

            <ResumeProduct>
                <div className="content-wrapper">
                    <div className="title-content">
                        <h3>Sobre o produto</h3>
                    </div>

                    <div className="description-content">
                        <p>
                            Compacta, mas potente e versátil, a nossa gama D-Series i-Tech
                            proporciona uma codificação flexível, de alta velocidade e elevada
                            qualidade numa vasta gama de materiais, tendo a capacidade para produzir
                            um número ilimitado de linhas de texto.
                        </p>
                    </div>
                </div>
            </ResumeProduct>

            <Divider size={1} orientation="horizontal" />

            <ContentProduct>
                <ReactMarkdown
                    className="contentCatalog"
                    source={`<p>
                            O sistema de marcação ponto a ponto montado na coluna ec1 é adequado
                            para a marcação de mesa em qualquer ambiente industrial. Ideal para
                            marcar peças pequenas e médias, sua velocidade de trabalho, qualidade
                            consistente e precisão confiável fazem dela uma máquina de marcação
                            altamente eficiente. Capaz de marcar plásticos e aço temperado (até
                            62HRC), a máquina de marcação de bancada ec1 usa uma caneta acionada por
                            solenoide que não requer um sistema pneumático separado e pode ser
                            combinada com equipamento de marcação opcional (eixo d ou placa). Requer
                            pouca manutenção e consumíveis, e é um sistema de marcação confiável e
                            resistente, adequado para uma infinidade de aplicações. O software ec1
                            foi criado para ser o mais simples possível, usando um teclado
                            industrial coberto com uma membrana e uma grande tela colorida. Este
                            software foi completamente repensado para fácil controle e maior
                            intuitividade. Oferece a criação fácil e rápida de todos os seus
                            arquivos de marcação diretamente no controlador.
                        </p>`}
                />
            </ContentProduct>

            <Divider size={1} orientation="horizontal" />

            <GalleryProduct>
                <h3 className="title-gallery">Galeria de fotos</h3>

                <Divider size={5} orientation="horizontal" />

                <GalleryCatalog
                    data={[
                        {
                            id: 1,
                            name: 'qw',
                            url:
                                'https://storage.googleapis.com/minascoding-store/ec1_1_1fbcde4a0c/ec1_1_ec1_1_1fbcde4a0c.jpeg',
                        },
                    ]}
                />
            </GalleryProduct>

            <Divider size={1} orientation="horizontal" />
        </Layout>
    )
}

export default SolutionPage
